import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Contact from '../components/Contact';
import Header from '../components/Header';
import PeopleOfColor from '../components/PeopleOfColor';

import rachelle from '../assets/images/author.jpg';
import demoImage1 from '../assets/images/demo-image-01.jpg';
import demoImage2 from '../assets/images/demo-image-02.jpg';
import collage from '../assets/images/collage.jpg';
import book from '../assets/images/pow.png';

const DownloadPage = () => (
  <Layout>
    <Header />
    <header>
      <section id="about" className="projects-section bg-gray">
        <div className="container">
          <div className="w-['full']">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: '40px',
                fontWeight: 800,
              }}
            >
              <div className="col-xl-4 col-lg-4">
                <img className="img-fluid bio" src={book} alt="" />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '40px',
                    marginRight: '10px',
                    fontWeight: 800,
                  }}
                >
                  <a
                    href="/People of Water.epub"
                    style={{
                      border: 'solid 1px #d77743',
                      padding: '10px 25px',
                      borderRadius: '5px',
                    }}
                  >
                    Download EPUB
                  </a>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '40px',
                    fontWeight: 800,
                  }}
                >
                  <a
                    href="/People of Water PDF.pdf"
                    style={{
                      border: 'solid 1px #d77743',
                      padding: '10px 25px',
                      borderRadius: '5px',
                      marginLeft: '10px',
                    }}
                  >
                    Download PDF
                  </a>
                </div>
              </div>
            </div>
            <div
              className="text-color text-center"
              style={{
                marginBottom: '80px',
                fontSize: '14px',
                border: '1px solid white',
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              <p>
                It's easy to send an ebook to your Kindle device or app! Just
                download the .epub file, log into your Amazon account, and then
                upload the book at this link:
                https://www.amazon.com/sendtokindle
              </p>
            </div>
          </div>
          <div className="row align-items-center flex justify-content-center mb-4 mb-lg-5">
            {/* <div className="col-xl-4 col-lg-4">
              <img className="img-fluid bio" src={book} alt="" />
            </div> */}
            <div className="col-xl-8 col-lg-7">
              <div className="featured-text text-color text-center text-center">
                <h2>Thanks for subscribing! We're basically friends now.</h2>
                <p>
                  <strong>
                    I'm so excited to share this novelette with you as a FREE
                    gift!
                  </strong>
                </p>
                <p>
                  <strong>People of Water</strong> is a standalone companion
                  story. It takes place one hundred thousand years prior to the
                  events in Sky of Seven Colors, a full-length novel by Rachelle
                  Nelson.
                </p>
                <p>
                  Out of the 832 souls living on the Vessel, there are only
                  fifty-nine children, and Calara is the oldest by far—a lonely
                  age to be. Her people fled their ancestral lands three
                  generations ago, learning to endure a harsh life on the open
                  sea. Now their numbers are dwindling. When Calara develops the
                  rare ability to dream the future, the Vessel's survival falls
                  into her young hands.
                </p>
                <p>
                  As she struggles to understand her own power, the dreams lead
                  to an encounter with a strange race of land dwellers. They
                  hold the key to either saving the Vessel's people or
                  destroying them, and Calara might be the only one who still
                  believes the future can change. But dreams aren't an exact
                  art, and choices make a difference in unpredictable ways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  </Layout>
);

export default DownloadPage;
